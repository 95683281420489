<template>
    <page-view class="share-goods" :up-class="'share-goods'">
        <div class="searcr">
            <div class="selects">
                <div class="select-item" v-for="(item, index) in toplist" :key="index" @click="lookAll(item)">
                    {{ item.name }}
                </div>
            </div>
            <div>
                <!-- <i class="el-icon-search" @click="searchInput" @mouseenter="isShow = true"></i>
                <el-input :class="isShow ? 'tolive' : ''" placeholder="请输入商品名称" v-model.trim="input"
                    @keypress.enter.native="searchInput" clearable></el-input> -->
                <div class="go" v-if="!islogin">
                    <div @click="onLogin" style="background-color: #439C4C;color: white;">登录</div>
                    <div @click="onRegister">注册</div>
                    <div>Hi~欢迎逛闲物互联</div>
                </div>
                <div v-else class="gocopy">
                    <div style="background-color:#439C4C">
                        <img @click="onMySelf" src="../../assets/img/BCCE.png" />
                    </div>
                    <div>
                        <el-popover placement="top-start" width="100" trigger="hover" @click="out">
                            <div slot="reference" style="cursor: pointer;color: black;">用户:{{ infoObj.name }}</div>
                            <div @click="out" style="cursor: pointer;color: black;">退出登录</div>
                        </el-popover>
                        <div>积分: 0</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner" @mouseenter="isShow = false">
            <div class="right-banner">
                <el-carousel style="height: 100%" height="100%" trigger="click" :interval="2000" arrow="hover">
                    <el-carousel-item v-for="item in bannerList" :key="item.id">
                        <img v-lazy="item.image" width="100%" height="100%" alt="" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="rr-banner">
                <div :data-info="rightList[0]?.itemList[0].title">
                    <el-carousel style="height: 100%" height="100%" trigger="click" :interval="2000"
                        :arrow="rightList[0]?.itemList.length > 1 ? 'always' : 'never'" indicator-position="none">
                        <el-carousel-item v-for="item in rightList[0]?.itemList" :key="item.id">
                            <img v-lazy="item.image" width="100%" height="100%" alt="" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div :data-info="rightList[1]?.itemList[0].title">
                    <el-carousel style="height: 100%" height="100%" trigger="click" :interval="2000"
                        :arrow="rightList[1]?.itemList.length > 1 ? 'always' : 'never'" indicator-position="none">
                        <el-carousel-item v-for="item in rightList[1]?.itemList" :key="item.id">
                            <img v-lazy="item.image" width="100%" height="100%" alt="" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>

        <div class="free">
            <div @click="lookAll(1)">
                <div>
                    <span>一</span><span>元</span><span>抢</span><span>购</span>
                </div>
                <div>查看详情<i class="el-icon-time"></i></div>
            </div>
            <div class="free_three">
                <div>
                    <img src="../../assets/icon/1.png" />
                </div>
                <div>
                    <div @click="lookAll(-7)"><img src="../../assets/icon/5.png" /><span>今日特惠</span></div>
                    <div @click="lookAll(-1)"><img src="../../assets/icon/3.png" /><span>最新上架</span></div>
                    <div @click="lookAll(-4)"><img src="../../assets/icon/4.png" /><span>好物租赁</span></div>
                    <div><img src="../../assets/icon/2.png" /><span>积分中心</span></div>
                </div>

                <div>
                    <div>精选热点</div>
                    <div style="font-weight: bold;">尽在闲物互联</div>
                </div>

                <!-- <div>
                    <div :style="!islogin ? {backgroundColor:'rgb(210, 210, 210)'} : {}">
                        <i class="el-icon-s-custom" v-if="!islogin" @click="onMySelf"></i>
                        <img v-else @click="onMySelf" src="../../assets/img/BCCE.png" />
                    </div>
                    <div>
                        <div>
                            <div v-if="!islogin">
                                <span @click="onLogin">登录</span>
                                <el-divider direction="vertical"></el-divider>
                                <span @click="onRegister">注册</span>
                            </div>
                            <div v-else>
                                <el-popover placement="top-start" width="100" trigger="hover" @click="out">
                                    <div slot="reference">用户:{{ infoObj.name }}</div>
                                    <div @click="out" style="cursor: pointer;">退出登录</div>
                                </el-popover>
                            </div>
                        </div>
                        <div>积分: 0</div>
                    </div>
                </div> -->

            </div>
            <div class="girds">
                <div v-for="item in freeList" :key="item.id" :data-my="item.name" @click="lookAll(item)"><img
                        :src="item.image" /></div>
            </div>
        </div>

        <div class="introduce">
            <div>
                <div class="title">闲物互联</div>
                <div class="content">
                    <div>
                        为了推进商展产业高质量发展，由深圳市展示道具行业孵化的百川智慧商展产业数字化平台2022年8月正式成立，创会会长骆诗学牵头全面推进产业数字化平台的系统建设，目前系统已整体开发完成，其整合了大量产业资源，初步进人内测阶段。计划于3月21平台发布，进入外测阶段，并于6月1号平台系统全面上线运营。
                    </div>
                    <div>根据测算，经平台将商展产业横向打通，纵向贯通，区域协同，跨界融合，商展产业如果按计划得到有效实施，将为社会带来2000亿以上的社会价值，降低社会成本，提高效率，造福社会。
                    </div>
                    <div>
                        百川智慧商展产业数字化平台的成功上线，标志着透明开放、共建共享产业数字化平台的建立，将为品牌提供提质降本增效的商业展示系统化服务，也将为产业可持续发展提供保障。而平台数据为政府决策提供有效的参考，为品牌高质量发展赋能，助力中国品牌走向世界。
                    </div>
                </div>
            </div>
        </div>

        <div class="hot_sale">
            <div class="title">
                <span>最新上架</span>
            </div>
            <div class="hot_sale_content">
                <div v-for="(item, index) in newList" :key="index" @click="toddd(item.id)">
                    <div><img :src="item.image" /></div>
                    <div>{{ item.description || '暂无描述' }}</div>
                    <div><span>￥{{ item.price }}元 &nbsp;<i class="el-icon-arrow-right"></i><i
                                class="el-icon-arrow-right"></i></span></div>
                </div>
            </div>
            <div class="title_more" v-if="newList.length > 0" @click="lookAll(-1)">查看更多</div>
            <div></div>
        </div>

        <div class="baozhang">
            <div>友情链接</div>
            <div><a target="blank" href="https://vip.abcmix.cn/addons/yb_shopv2/pc/1/"><img src="../../assets/icon/333.jpg"/></a></div>
        </div>

        <footer-com></footer-com>
    </page-view>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    data() {
        return {
            input: "",
            isShow: false,
            bannerList: [],//左侧轮播图数组
            rightList: [],//右侧展示
            toplist: [],//顶部类别
            freeList: [],//grid布局四分列
            newList: [],//最新上架
        };
    },
    computed: {
        ...mapState(["screenWidth", "infoObj"]),
        islogin() {//监听登录状态,控制个人中心显示和隐藏
            if (this.infoObj.account && this.infoObj.phone && window.localStorage.getItem('token')) {
                return true
            } else {
                window.localStorage.removeItem('token')
                return false
            }
        }
    },
    created() {
        this.rightImg()
        this.getlist()
        this.getGoodClassBanner()
        this.getGoodClass(-1)//最新上架
    },
    methods: {
        ...mapActions(["setGoodFenLei", "setNavIndex", "setInfoObj"]),
        searchInput() {
            const openRoute = this.$router.resolve({
                name: "ShareGoodsAll",
                query: {
                    input: this.input,
                },
            });
            window.open(openRoute.href, "_blank");
        },
        async getGoodClassBanner() {
            let result = await this.$api.getBanner('PT00666');
            this.bannerList = result.itemList;
        },
        onLogin() {
            this.setNavIndex("dddd");
            this.$router.push({ name: "Login", });
        },
        onRegister() {
            this.setNavIndex("dddd");
            this.$router.push({
                name: "Register1",
            });
        },
        //退出登录
        out() {
            this.setInfoObj({
                account: '',
                id: null,
                status: null,
                sex: null,
                phone: '',
                companyid: null,
                name: ''
            })
            window.localStorage.removeItem("token")
        },

        onMySelf() {
            this.setNavIndex("dddd");
            this.$router.push({
                name: "MySelf",
            });
        },
        async getlist() {//获取顶部商城类别接口
            let result = await this.$api.getMaterdd();
            this.toplist = result.data
            this.setGoodFenLei(result.data);//将分类信息提供给全局，方便分类页快速显示
        },
        async rightImg() {
            let res1 = await this.$api.getBanner('PT00680')
            let res2 = await this.$api.getBanner('PT00681')
            let ress = await this.$api.getMaterdds()
            this.freeList = ress.data
            this.rightList.push(res1)
            this.rightList.push(res2)
        },
        async getGoodClass(num) {
            let result = await this.$api.getMatergood(
                {
                    categoryId: num,
                    type: 17,
                    size: 10,
                }
            );
            this.newList = result.data.list
        },

        toddd(item) {
            const openRoute = this.$router.resolve({
                name: "ArticlesDetailGood",
                params: {
                    goodsId: item,
                    isGoodFrom: 'true',
                },
            });
            window.open(openRoute.href, "_blank");
        },

        lookAll(item) {
            if (Object.prototype.toString.call(item) === "[object Object]") {
                const openRoute = this.$router.resolve({
                    name: 'ShareGoodsAll',
                    query: {
                        id: item.id,
                        pid: item.parentId,
                    },
                });
                window.open(openRoute.href, "_blank");
            } else {
                if (item == 1) {//一元购
                    const openRoute = this.$router.resolve({
                        name: 'ShareGoodsAll',
                        query: {
                            wasOne: 1,
                        },
                    });
                    window.open(openRoute.href, "_blank");
                } else {
                    const openRoute = this.$router.resolve({
                        name: 'ShareGoodsAll2',
                        query: {
                            id: item,
                        },
                    });
                    window.open(openRoute.href, "_blank");
                }
            }
        },
    },
};
</script>
<style lang='scss' scoped>
.share-goods {
    flex: 1;
    overflow: auto;
    background-color: #f3f5f8;

    .title {
        font-size: max(16px, 2.6vw);
        // font-family: 'title';
        color: black;
        font-weight: 500;
        text-align: center;
        letter-spacing: 8px;
        padding-bottom: 2%;
        border-bottom: 2px solid black;
        width: 80%;
        margin: auto;
    }

    .title_more {
        background-color: #D6D6D6;
        color: #212121;
        display: inline-block;
        padding: max(7px, 0.8%) max(20px, 3%);
        font-size: 14px;
        cursor: pointer;
        transition: all .5s;

        &:hover {
            color: #439C4C;
        }
    }

    .contentall {
        padding: 3% 0% 0%;
        font-size: 14px;
        text-align: center;
        background-color: #fff;
        box-sizing: border-box;
    }

    .searcr {
        width: 95%;
        margin: 1% auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        color: rgb(38, 38, 38);
        font-weight: 400;
        font-size: 0.8vw;

        .selects {
            flex: 5;
            display: flex;
            flex-wrap: wrap;

            >div {
                padding: 0% 1%;
                border-right: 1px solid #dedede;
                cursor: pointer;
                white-space: nowrap;
                margin-top: 1%;

                &:hover {
                    color: #439C4C;
                }
            }

            >div:last-child {
                border: none;
            }
        }

        >div:nth-child(2) {
            position: relative;
            display: flex;
            justify-content: flex-end;
            gap: 10%;
            color: #439C4C;
            flex: 2;

            .go {
                width: 100%;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 2%;

                >div:not(:last-child) {
                    border: 1px solid #d3d3d3;
                    cursor: pointer;
                    padding: 1% 3%;
                    border-radius: 20px;
                    box-sizing: border-box;

                    &:hover {
                        color: white;
                        background-color: #439C4C;
                    }
                }

                @media screen and (max-width: 700px) {
                    justify-content: center;
                }
            }

            .gocopy {
                display: flex;
                align-items: center;
                white-space: nowrap;
                gap: 10px;

                >div:nth-child(1) {
                    background-color: #439C4C;
                    border-radius: 50%;
                    width: max(20px, 3vw);
                    height: max(20px, 3vw);
                    text-align: center;
                    letter-spacing: 0px;

                    >img {
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                    }

                    >i {
                        color: white;
                        font-size: max(10px, 2vw);
                        line-height: 1.3;
                        cursor: pointer;
                    }
                }

                >div:nth-child(2) {
                    display: flex;
                    justify-content: center;
                    color: rgb(194, 194, 194);
                    flex-direction: column;
                    letter-spacing: 5px;
                    position: relative;

                    >div {
                        padding: 1.5% 0%;
                        border-radius: 20px;
                        margin-bottom: 3%;
                        letter-spacing: 3px;
                    }

                    >div:nth-child(1) {
                        color: black;
                        cursor: pointer;

                        >div>span {
                            &:hover {
                                color: #439C4C;
                            }
                        }
                    }
                }
            }

            // :deep(.el-input) {
            //     position: absolute;
            //     left: 0%;
            //     top: 50%;
            //     transform: translate(10%, -50%);
            //     overflow: hidden;
            //     max-width: 0px;
            //     box-sizing: border-box;
            //     transition: all .7s ease-in-out;
            //     opacity: 0;

            //     .el-input__inner {
            //         padding-left: 5%;
            //         box-sizing: border-box;
            //         border: none;
            //         background-color: transparent;
            //     }
            // }

            // >i {
            //     width: max(30px, 2vw);
            //     height: max(30px, 2vw);
            //     line-height: max(30px, 2vw);
            //     font-size: max(14px, 1.2vw);
            //     text-align: center;
            //     background-color: #439C4C;
            //     border-radius: 50%;
            //     cursor: pointer;
            //     color: white;
            // }
        }

        .tolive {
            opacity: 1 !important;
            max-width: 2000px !important;
            border-bottom: 1px solid #469c4c;
        }
    }

    .banner {
        margin: 0 auto;
        @include flexbox(row);
        aspect-ratio: 16/6.1;

        .right-banner {
            flex: 2;
            background-color: #e8e8e8;
            position: relative;

            .letf-list-content {
                background-color: #fff;
                box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
                height: 100%;
                width: 60%;
                position: absolute;
                padding: 20px;
                box-sizing: border-box;
                z-index: 99;

                .type-item {
                    margin-bottom: 10px;

                    .item-title {
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }

                    >ul {
                        @include flexbox(row) {
                            flex-wrap: wrap;
                        }

                        >li {
                            font-size: 14px;
                            color: #6b6b6b;
                            margin-right: 3%;
                            margin-bottom: 1%;

                            &:hover {
                                color: $primaryColor;
                                text-decoration: underline;
                            }
                        }

                        &:empty::after {
                            content: '敬请期待...';
                        }
                    }
                }
            }

            img {
                object-fit: cover !important;
            }
        }

        .rr-banner {
            flex: 1;
            margin-left: 1%;

            >div {
                width: 100%;
                height: 49%;
                position: relative;
                font-size: max(14px, 1.6vw);
                overflow: hidden;
                font-family: '微软雅黑';
                letter-spacing: 8px;

                &::before {
                    transition: all .7s;
                    position: absolute;
                    z-index: 600;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    content: '';
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.7);
                    opacity: 0;
                }

                &::after {
                    transition: all .7s;
                    white-space: nowrap;
                    position: absolute;
                    z-index: 600;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    opacity: 0;
                    color: white;
                    font-weight: 500;
                    content: attr(data-info);
                }

                &:hover {
                    &::after {
                        opacity: 1 !important;
                        transform: translate(-50%, -50%) !important;
                    }

                    &::before {
                        opacity: 1 !important;
                    }
                }
            }

            >div:nth-child(1) {
                margin-bottom: 2.5%;
            }

            >div:nth-child(2) {
                margin-top: 2.5%;
            }
        }
    }

    .free {
        font-weight: 600;
        letter-spacing: 10px;
        font-family: '宋体';

        >div:nth-child(1) {
            font-size: max(24px, 3.5vw);
            font-weight: 400;
            text-align: center;
            padding: 1% 0%;
            margin: 2% 0%;
            color: white;
            cursor: pointer;
            background-image: url(../../assets/img/free.jpg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            >div:nth-child(1) {
                >span {
                    display: inline-block;
                    background-image: url(../../assets/icon/11.png);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 100% 100%;
                    margin: 0% 0.2%;
                    padding: 0.5% 0.8%;
                    text-align: center;
                    letter-spacing: 0px;
                }
            }

            >div:nth-child(2) {
                letter-spacing: 2px;
                font-weight: 500;
                font-size: max(14px, 0.8vw);
                margin-top: 1%;

                >i {
                    transition: all 1s;
                    transform: translateY(-10%) rotate(-135deg);
                }
            }
        }

        .free_three {
            display: flex;
            width: 99%;
            margin: auto;
            box-shadow: 3px 3px 10px rgb(207, 207, 207), -3px -3px 5px rgb(207, 207, 207);
            padding: 0% 3%;
            box-sizing: border-box;
            font-weight: 400;
            font-family: '微软雅黑';

            @media screen and (max-width: 500px) {
                flex-direction: column;
                align-items: center;

                >div:nth-child(3) {
                    width: auto !important;

                    &::before {
                        display: none;
                    }
                }
            }

            >div:nth-child(1) {
                width: 18%;
                display: flex;
                justify-content: center;
                align-items: center;

                >img {
                    width: 60%;
                }
            }

            >div:nth-child(2) {
                display: flex;
                gap: 2%;
                padding: 1% 5%;
                box-sizing: border-box;
                overflow: hidden;
                flex: 1;

                >div {
                    flex: 1;
                    letter-spacing: 0px;
                    font-size: max(12px, 0.9vw);
                    color: black;
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    >img {
                        width: 20%;
                        margin-bottom: 5%;
                    }
                }
            }

            >div:nth-child(3) {
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #E60B24;
                flex-direction: column;
                letter-spacing: 5px;
                position: relative;

                &::before {
                    content: '';
                    background-color: #E2E4E8;
                    width: 2px;
                    height: 70%;
                    position: absolute;
                    left: -9%;
                    top: 50%;
                    transform: translateY(-50%);
                }

                >div:nth-child(1) {
                    background-color: #E60B24;
                    color: white;
                    padding: 1.5% 5%;
                    border-radius: 20px;
                    margin-bottom: 3%;
                    letter-spacing: 3px;
                }
            }

            // >div:nth-child(3) {
            //     width: 20%;
            //     display: flex;
            //     align-items: center;
            //     position: relative;
            //     white-space: nowrap;
            //     gap: 10px;

            //     >div:nth-child(1) {
            //         background-color: #439C4C;
            //         border-radius: 50%;
            //         width: max(20px, 3vw);
            //         height: max(20px, 3vw);
            //         text-align: center;
            //         letter-spacing: 0px;
            //         >img {
            //             width: 100%;
            //             height: 100%;
            //             cursor: pointer;
            //         }
            //         >i{
            //             color: white;
            //             font-size: max(10px, 2vw);
            //             line-height: 1.3;
            //             cursor: pointer;
            //         }
            //     }

            //     &::before {
            //         content: '';
            //         background-color: #E2E4E8;
            //         width: 2px;
            //         height: 70%;
            //         position: absolute;
            //         left: -9%;
            //         top: 50%;
            //         transform: translateY(-50%);
            //     }

            //     >div:nth-child(2) {
            //         display: flex;
            //         justify-content: center;
            //         color: rgb(194, 194, 194);
            //         flex-direction: column;
            //         letter-spacing: 5px;
            //         position: relative;

            //         >div {
            //             padding: 1.5% 5%;
            //             border-radius: 20px;
            //             margin-bottom: 3%;
            //             letter-spacing: 3px;
            //         }

            //         >div:nth-child(1) {
            //             color: black;
            //             cursor: pointer;

            //             >div>span {
            //                 &:hover {
            //                     color: #439C4C;
            //                 }
            //             }
            //         }
            //     }
            // }
        }

        .girds {
            display: grid;
            box-sizing: border-box;
            gap: 10px;
            grid-template-columns: calc(50% - 5px) calc(50% - 5px);
            grid-template-rows: 45% 10% 45%;
            grid-template-areas: 'a b'
                'c b'
                'c d';
            width: 90%;
            aspect-ratio: 5/1.6;
            font-size: 1.5vw;
            font-weight: 400;
            color: white;
            margin: 2% auto;
            font-family: '微软雅黑';

            >div {
                position: relative;
                cursor: pointer;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                >img[src=''] {
                    opacity: 0;
                }

                &::before {
                    transition: all .7s;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    content: '';
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.6);
                    opacity: 0;
                }

                &::after {
                    transition: all .7s;
                    white-space: nowrap;
                    position: absolute;
                    content: attr(data-my);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    opacity: 0;
                    color: white;
                    font-weight: 500;
                }

                &:hover {
                    &::after {
                        opacity: 1 !important;
                        transform: translate(-50%, -50%) !important;
                    }

                    &::before {
                        opacity: 1 !important;
                    }
                }
            }



            >div:nth-child(1) {
                background-color: #360253;
                grid-area: a;
            }

            >div:nth-child(2) {
                background-color: #0d94ca;
                grid-area: b;
            }

            >div:nth-child(3) {
                background-color: #df0adf;
                grid-area: c;
            }

            >div:nth-child(4) {
                background-color: #7acc0f;
                grid-area: d;
            }
        }
    }

    .introduce {
        background-image: linear-gradient(to bottom, #439C4C 60%, white 40%);
        padding: 4% 5%;

        >div {
            background-color: white;
            padding: 3%;
            box-shadow: 3px 3px 10px rgb(207, 207, 207);
            font-size: max(14px, 0.9vw);

            .title {
                width: 95%;
                @extend .title;
            }

            .content {
                margin: 2% auto;
                width: 95%;
                line-height: max(20px, 2.5vw);
                letter-spacing: 3px;

                >div {
                    text-indent: 2em;
                }
            }
        }
    }

    .hot_sale {
        border-radius: 5px;
        @extend .contentall;

        .title {
            @extend .title
        }

        .hot_sale_content {
            text-align: start;
            width: 88%;
            margin: 4% auto;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
            cursor: pointer;
            gap: 2vw;
            row-gap: 5vw;
            font-size: 14px;
            position: relative;

            >div {
                font-size: max(12px, 0.9vw);

                >div:nth-child(1) {
                    width: 100%;
                    aspect-ratio: 4/3.5;
                    background-color: #D9D9D9;

                    >img {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                    }
                }

                >div:nth-child(2) {
                    width: 100%;
                    border-bottom: 2px solid #dcdcdc;
                    padding: 4% 0%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                >div:nth-child(3) {
                    width: 100%;
                    padding-top: max(10px, 5%);

                    >span {
                        display: inline-block;
                        padding: max(5px, 2%) max(5px, 6%) max(5px, 2%) max(5px, 2%);
                        box-sizing: border-box;
                        color: #FFFFFF;
                        background-color: #439C4C;
                        flex: 3;

                        >i {
                            font-size: max(12px, 1vw);
                            width: 6px;
                        }
                    }
                }
            }

            &:empty::after {
                content: '敬请期待...';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .title_more {
            background-color: #E6E6E6;
            @extend .title_more
        }

        >div:last-child {
            padding-top: 2%;
            // height: 3vw;
            width: 100%;
            // background-color: #439C4C;
        }
    }

    .baozhang {
        background-color: #F5F5F5;
        padding: 2% 10%;
        display: flex;
        >div{
            height: 4vw;
            line-height: 4vw;
            position: relative;
            text-align: center;
            font-size: 1vw;
            padding-right: 2vw;
            box-sizing: border-box;
            font-weight: bold;
            img{
                height: 100%;
                cursor: pointer;
            }
        }
        >div:not(:first-child){
            padding: 0vw 2vw;
            &::after{
                content: '';
                background-color: rgb(223, 223, 223);
                height: 120%;
                width: 2px;
                position: absolute;
                top: 50%;
                left: 0%;
                transform: translateY(-50%);
            }
        }
    }
}






@media screen and (max-width: 600px) {
    .share-goods {
        .searcr {
            width: 100%;
            flex-direction: column;

            .selects {
                width: 100%;
                margin: 3% auto;
                justify-content: center;
            }

            >div:nth-child(2) {
                width: 100%;
            }
        }

        .free {
            letter-spacing: 0px;

            .girds {
                gap: 4px;
            }
        }

        .hot_sale {
            .hot_sale_content {
                font-size: 12px;
                grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));

                >div {
                    >div:nth-child(3) {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}</style>